import React, { useEffect, Suspense, lazy, useState, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UserContext } from './UserContext';
import { Worker } from '@react-pdf-viewer/core';
import './App.css';
import './css/app2.min.css'
import './css/bookshelf.min.css'
import './css/main.css'
import './css/myStyle.css'

const ConnectNav = lazy(() => import( './js/components/Navbar/Navbar'));
const SideNav = lazy(() => import( './js/components/Sidebar/sidebar'));
const NotFound = lazy(() => import( './js/components/NotFound/NotFound'));
const Users = lazy(() => import( './js/views/Users/Users'));
const Calendar = lazy(() => import( './js/views/Calendar/calendar'));
const Documents = lazy(() => import( './js/views/Documents/documents'));
const Chat = lazy(() => import( './js/views/Chat/chat'));
const Login = lazy(() => import( './js/views/Login/login'));
const ConfirmEmail = lazy(() => import( './js/views/ConfirmEmail/confirmEmail'));
const LebijouConfirmEmail = lazy(() => import( './js/views/ConfirmEmail/lebijouConfirmEmail'));
const Form = lazy(() => import( './js/views/Form/form'));
const Events = lazy(() => import( './js/views/Events/events'));
const Interviews = lazy(() => import( './js/views/Interviews/interviews'));
const InterviewDetails = lazy(() => import( './js/views/Interviews/interviewDetails'));
const UsersDetails = lazy(() => import( './js/views/Users/UsersDetails'));
const UserProjectDetail = lazy(() => import( './js/views/Users/UserProjectDetail'));
const Credit = lazy(() => import( './js/views/Credit/Credit'));
const Courses = lazy(() => import( './js/views/Courses/Courses'));
const Forme = lazy(() => import( './js/views/Forme/Forme'));
const Text = lazy(() => import( './js/views/Text/Text'));
const Legacy = lazy(() => import( './js/views/Legacy/Legacy'));
const Payments = lazy(() => import( './js/views/Directory/directory'));
const Docusign = lazy(() => import( './js/views/Docusign/Docusign'));
const SignDocument = lazy(() => import( './js/views/Docusign/SignDocument'));

const LoggedHome = lazy(() => import('./js/views/LoggedHome/LoggedHome'));


function App() {

  const [backen_url] = useState('https://uit-connect-backend.herokuapp.com/api/auth/');
  const [interviewBackend] =useState('https://uit-connect-backend.herokuapp.com/api/interview/getInterviews');
  const [projectBackend] =useState('https://uit-connect-backend.herokuapp.com/api/project/getProjects');
  const [projectCommentGetDataEndpoint] =useState('https://uit-connect-backend.herokuapp.com/api/project/getComments');
  const [coursesGetDataEndpoint] =useState('https://uit-connect-backend.herokuapp.com/api/course/getCourses');
  const [taskGetDataEndpoint] =useState('https://uit-connect-backend.herokuapp.com/api/task/getTask');
  const [textGetDataEndpoint] =useState('https://uit-connect-backend.herokuapp.com/api/text/getText');
  const [GetGroupChat] =useState('https://uit-connect-backend.herokuapp.com/api/group_chat/getGroupChat');
  const [GetMessageChat] =useState('https://uit-connect-backend.herokuapp.com/api/message_chat/getMessageChat');
  const [GetLegacyStudents] =useState('https://uit-connect-backend.herokuapp.com/api/legacynewstudent/getlagacyStudent');
  const [GetContractorsPayment] =useState('https://uit-connect-backend.herokuapp.com/api/contractors/getContractorsPayment');
  const [user, setUser] = useState(null);
  const [Auth, setAuth] = useState(null);
  let emailAuth = localStorage.getItem('email');
  const [consultantData, setconsultantData] = useState([]);
  const [interviewData, setInterviewData] = useState([]);
  const [projectsData, setprojectsData] = useState([]);
  const [projectsCommentData, setprojectsCommentData] = useState([]);
  const [coursesData, setcoursesDataData] = useState([]);
  const [taskData, settaskData] = useState([]);
  const [textData, settextData] = useState([]);
  const [groupChatData, setgroupChatData] = useState([]);
  const [messageChatData, setmessageChatData] = useState([]);
  const [legacyStudentsData, setlegacyStudentsData] = useState([]);
  const [contractorsPaymentData, setcontractorsPaymentData] = useState([]);
  const [refreshMessagesData, setrefreshMessagesData] = useState(false);


  useEffect(() => {
    fetch(backen_url+'users')
      .then(res => res.json())
      .then(res => setconsultantData(res))
      .catch(error => console.log('error: ', error) );
    }, [backen_url]);

  useEffect(() => {
    fetch(interviewBackend)
      .then(res => res.json())
      .then(res => setInterviewData(res))
      .catch(error => console.log('error: ', error) );
    },[interviewBackend]);

  useEffect(() => {
    fetch(projectBackend)
      .then(res => res.json())
      .then(res => setprojectsData(res))
      .catch(error => console.log('error: ', error) );
    },[projectBackend]);

  useEffect(() => {
    fetch(projectCommentGetDataEndpoint)
      .then(res => res.json())
      .then(res => setprojectsCommentData(res))
      .catch(error => console.log('error: ', error) );
    },[projectCommentGetDataEndpoint]);

  useEffect(() => {
      fetch(coursesGetDataEndpoint)
        .then(res => res.json())
        .then(res => setcoursesDataData(res))
        .catch(error => console.log('error: ', error) );
      },[coursesGetDataEndpoint]);

  useEffect(() => {
      fetch(taskGetDataEndpoint)
        .then(res => res.json())
        .then(res => settaskData(res))
        .catch(error => console.log('error: ', error) );
      },[taskGetDataEndpoint]);

  useEffect(() => {
      fetch(textGetDataEndpoint)
        .then(res => res.json())
        .then(res => settextData(res))
        .catch(error => console.log('error: ', error) );
      },[textGetDataEndpoint]);

  useEffect(() => {
      fetch(GetGroupChat)
        .then(res => res.json())
        .then(res => setgroupChatData(res))
        .catch(error => console.log('error: ', error) );
      },[GetGroupChat]);

  useEffect(() => {
    if (refreshMessagesData) {
      fetch(GetMessageChat)
        .then(res => res.json())
        .then(res => {setmessageChatData(res);setrefreshMessagesData(false)})
        .catch(error => console.log('error: ', error) );
    }
      },[GetMessageChat,refreshMessagesData]);

  useEffect(() => {
    setrefreshMessagesData(true);
  },[])

  useEffect(() => {
    fetch(GetLegacyStudents)
      .then(res => res.json())
      .then(res => setlegacyStudentsData(res))
      .catch(error => console.log('error: ', error) );
    },[GetLegacyStudents]);

  useEffect(() => {
    fetch(GetContractorsPayment)
      .then(res => res.json())
      .then(res => setcontractorsPaymentData(res))
      .catch(error => console.log('error: ', error) );
    },[GetContractorsPayment]);

  const providerValue = useMemo(() => ({
    user, 
    setUser,
    backen_url,
    Auth,
    setAuth,
    consultantData,
    interviewData,
    projectsData,
    projectsCommentData,
    coursesData,
    taskData,
    textData,
    groupChatData,
    setgroupChatData,
    messageChatData,
    setmessageChatData,
    refreshMessagesData,
    setrefreshMessagesData,
    legacyStudentsData,
    setlegacyStudentsData,
    contractorsPaymentData,
    setcontractorsPaymentData
  }), [
    user, 
    setUser,
    backen_url,
    Auth,
    setAuth,
    consultantData,
    interviewData,
    projectsData,
    projectsCommentData,
    coursesData,
    taskData,
    textData,
    groupChatData,
    setgroupChatData,
    messageChatData,
    setmessageChatData,
    refreshMessagesData,
    setrefreshMessagesData,
    legacyStudentsData,
    setlegacyStudentsData,
    contractorsPaymentData,
    setcontractorsPaymentData
  ]);
  
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
    <Router>
      <UserContext.Provider value={providerValue}>
        <Suspense fallback={<div>Loading...</div>}>
        <div className="router-layout-container">
          {(emailAuth === "" || emailAuth === null) ? "" : <ConnectNav/>}
          <div className="router-layout-content">
            {(emailAuth === "" || emailAuth === null) ? "" : <SideNav/>}

            {(emailAuth === "" || emailAuth === null) ? 
            <Routes>
              <Route exact path="/" element={ <Login/> } />
              <Route path="/confirmemail/:token" element={ <ConfirmEmail/> } />
              <Route path="/lebijouconfirmemail/:token" element={ <LebijouConfirmEmail/> } />
              <Route path="/sign-document/:id" element={<SignDocument />} />
              <Route path='*' exact={true} element={<NotFound/>} />
            </Routes> :
            <Routes>
              <Route exact path="/" element={ <LoggedHome/> }/>
              <Route path="/users" element={<Users />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="/forms" element={<Form />} />
              <Route path="/events" element={<Events />} />
              <Route path="/interviews" element={<Interviews />} />
              <Route path="/interviewDetails/:id" element={<InterviewDetails />} />
              <Route path="/usersdetails/:id" element={<UsersDetails />} />
              <Route path="/userproject/:id" element={<UserProjectDetail />} />
              <Route path="/credit" element={<Credit />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/forme" element={<Forme />} />
              <Route path="/text" element={<Text />} />
              <Route path="/staffing" element={<Legacy />} />
              <Route path="/payments" element={<Payments />} />
              <Route path="/docusign" element={<Docusign />} />
              <Route path="/confirmemail/:token" element={ <ConfirmEmail/> } />
              <Route path="/login" element={<Login />} />
              <Route path='*' exact={true} element={<NotFound/>} />
            </Routes> 
            }
          </div>
        </div>
        </Suspense>
      </UserContext.Provider>
    </Router>
    </Worker>
  );
}

export default App;
